import { Box, Tab, Tabs } from '@mui/material';
import { useState, ReactNode, useCallback } from 'react';

import { courses as types } from '@codesass/types';

import LessonDiscussion from './LessonDiscussion';

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
};

type LessonAsideProps = {
  slug: types.Lesson['slug'];
  courseSlug: types.Course['slug'];
};

const LessonAside = (props: LessonAsideProps) => {
  const [value, setValue] = useState(0);

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    },
    []
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} textColor="inherit" onChange={handleChange}>
          <Tab label="ถาม / ตอบ" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <LessonDiscussion {...props}></LessonDiscussion>
      </TabPanel>
    </Box>
  );
};

export default LessonAside;
