import { useCallback } from 'react';

import {
  courses as coursesTypes,
  comments as commentsTypes,
} from '@codesass/types';
import { comments as api } from '@codesass/api';

import Discussion from 'modules/comments/components/Discussion';

type LessonDiscussionProps = {
  slug: coursesTypes.Lesson['slug'];
  courseSlug: coursesTypes.Course['slug'];
};

const LessonDiscussion = ({ courseSlug, slug }: LessonDiscussionProps) => {
  const saveComment = useCallback(
    ({ content }: Pick<commentsTypes.Comment, 'content'>) => {
      api.addComment({
        type: 'lesson',
        courseSlug,
        lessonSlug: slug,
        content,
      });
    },
    [courseSlug, slug]
  );

  const loadDiscussion = useCallback(async () => {
    return api.getComments({
      type: 'lesson',
      courseSlug,
      lessonSlug: slug,
    });
  }, [courseSlug, slug]);

  const reply = useCallback(
    (id: string, { content }: Pick<commentsTypes.Comment, 'content'>) => {
      api.replyComment({
        id,
        type: 'lesson',
        courseSlug,
        lessonSlug: slug,
        content,
      });
    },
    [courseSlug, slug]
  );

  return (
    <Discussion
      saveBtnTitle="เพิ่มคำถาม"
      noDiscussionText="ไม่พบคำถาม / คำตอบ"
      onSave={saveComment}
      onLoad={loadDiscussion}
      canReply={true}
      replyButtonText="ตอบกลับ"
      replyPlaceholder="ระบุข้อความตอบกลับ"
      onReply={reply}
    ></Discussion>
  );
};

export default LessonDiscussion;
