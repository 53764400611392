import { ReactNode, useMemo, useReducer } from 'react';
import { courses as types } from '@codesass/types';

import QuestionsContext, { QuestionsContextValue } from '../contexts/Questions';
import reducer, { initialState, State } from '../reducers/questions';

type QuestionsProviderProps = {
  courseSlug: types.Course['slug'];
  courseName: types.Course['title'];
  certificationTitle?: types.CertificationExamLesson['certificationTitle'];
  questions: State['questions'];
  time?: State['time'];
  children: ((_context: QuestionsContextValue) => ReactNode) | ReactNode;
};

const QuestionsProvider = ({
  courseSlug,
  courseName,
  certificationTitle,
  questions,
  time,
  children,
}: QuestionsProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(
    () => ({
      ...state,
      courseSlug,
      courseName,
      certificationTitle,
      questions,
      time,
      dispatch,
    }),
    [certificationTitle, courseName, courseSlug, questions, state, time]
  );

  return (
    <QuestionsContext.Provider value={contextValue}>
      {typeof children === 'function' ? children(contextValue) : children}
    </QuestionsContext.Provider>
  );
};

export default QuestionsProvider;
