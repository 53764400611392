import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import useQuestions from 'modules/courses/hooks/useQuestions';

import QuestionSelectorBar from '../QuestionSelectorBar';

const ViewCorrectAnswers = () => {
  const { answers, currentQuestionIndex, currentQuestion, reset } =
    useQuestions();

  if (!currentQuestion) return null;

  return (
    <>
      <Box fontSize={theme => theme.typography.h6.fontSize}>
        <MDXRenderer>{currentQuestion.question}</MDXRenderer>
      </Box>
      <RadioGroup key={currentQuestionIndex}>
        {currentQuestion.choices.map((choice, index) => {
          const isCorrectAnswer = currentQuestion.answer === index;

          return (
            <FormControlLabel
              key={`${currentQuestionIndex}-${index}`}
              value={index}
              control={<Radio />}
              label={<MDXRenderer>{choice}</MDXRenderer>}
              checked={
                answers[currentQuestionIndex] === index || isCorrectAnswer
              }
              disabled={!isCorrectAnswer}
            />
          );
        })}
      </RadioGroup>
      <Box
        sx={{
          position: 'fixed',
          bottom: theme => theme.spacing(1),
          right: theme => theme.spacing(1),
        }}
      >
        <QuestionSelectorBar></QuestionSelectorBar>
        <Button variant="outlined" color="inherit" size="large" onClick={reset}>
          ทำแบบทดสอบอีกครั้ง
        </Button>
      </Box>
    </>
  );
};

export default ViewCorrectAnswers;
