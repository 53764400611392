import { Stack, Box, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';

const ViewResult = () => {
  return (
    <Stack alignItems="center">
      <Box maxWidth={350} my={2}>
        <StaticImage
          src="../../../../../assets/images/lesson-permission-denied.png"
          alt="Creator"
        />
      </Box>
      <Typography variant="h5" my={2}>
        การทดสอบเสร็จสิ้น ผู้สอนของคุณกำลังตรวจทานและอนุมัติผลการสอบภายใน 24
        ชั่วโมง
      </Typography>
    </Stack>
  );
};

export default ViewResult;
