import {
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  NavigateBefore,
  NavigateNext,
  RadioButtonChecked,
} from '@mui/icons-material';

import { useCallback, useState } from 'react';

import useQuestions from 'modules/courses/hooks/useQuestions';

const QuestionSelectorBar = () => {
  const {
    questions,
    currentQuestionIndex,
    changeCurrentQuestionIndex,
    isAnswerSelected,
    allAnswersSelected,
  } = useQuestions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeSelector = () => {
    setAnchorEl(null);
  };

  const changeQuestion = useCallback(
    (index: number) => () => {
      changeCurrentQuestionIndex(index);
      closeSelector();
    },
    [changeCurrentQuestionIndex]
  );

  return (
    <>
      <IconButton
        onClick={changeQuestion(currentQuestionIndex - 1)}
        disabled={currentQuestionIndex === 0}
      >
        <NavigateBefore></NavigateBefore>
      </IconButton>
      <Button
        id="select-question"
        variant={allAnswersSelected ? 'text' : 'contained'}
        color={allAnswersSelected ? 'inherit' : 'primary'}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          px: 2,
          mx: 1,
          borderRadius: theme => theme.shape.borderRadius,
          fontSize: theme => theme.typography.h6.fontSize,
        }}
      >
        เลือกข้อคำถาม
      </Button>
      <IconButton
        onClick={changeQuestion(currentQuestionIndex + 1)}
        disabled={currentQuestionIndex === questions.length - 1}
      >
        <NavigateNext></NavigateNext>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeSelector}
        MenuListProps={{
          'aria-labelledby': 'select-question',
        }}
        PaperProps={{
          style: {
            maxHeight: 200,
          },
        }}
      >
        {questions.map((_question, index) => {
          const isCurrentQuestion = currentQuestionIndex === index;

          return (
            <MenuItem
              key={index}
              onClick={changeQuestion(index)}
              sx={{
                color: isCurrentQuestion ? 'primary.main' : 'inherit',
                backgroundColor: isCurrentQuestion ? 'black' : 'inherit',
                ':hover': { color: 'inherit' },
              }}
            >
              ข้อที่ {index + 1}
              {isAnswerSelected(index) && (
                <ListItemIcon>
                  <RadioButtonChecked
                    color="primary"
                    sx={{ ml: 'auto' }}
                  ></RadioButtonChecked>
                </ListItemIcon>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default QuestionSelectorBar;
