import { Reducer } from 'react';
import { courses as types } from '@codesass/types';

import { UnreachableCaseError } from 'modules/shared/UnreachableCaseError';

export type State = {
  courseSlug: types.Course['slug'];
  courseName: types.Course['title'];
  certificationTitle?:
    | types.CertificationExamLesson['certificationTitle']
    | undefined;
  questions: types.Question[];
  currentQuestionIndex: number;
  answers: Record<number, number>;
  submitted: boolean;
  timeExceed: boolean;
  isCorrectAnswersShown: boolean;
  startedAt?: Date | undefined;
  time?: number | undefined;
};

type StartExam = {
  type: 'START_EXAM';
  payload: { startedAt: Date };
};

type SetCurrentQuestionIndexAction = {
  type: 'SET_CURRENT_QUESTION_INDEX_ACTION';
  payload: { index: number };
};

type SetAnswer = {
  type: 'SET_ANSWER';
  payload: {
    question: number;
    answer: number;
  };
};

type SubmitAnswers = {
  type: 'SUBMIT_ANSWERS';
  payload: {
    status: 'SUCCESS' | 'TIME_EXCEED';
  };
};

type Reset = {
  type: 'RESET';
};

type ShowCorrectAnswers = {
  type: 'SHOW_CORRECT_ANSWERS';
};

export type Actions =
  | StartExam
  | SetCurrentQuestionIndexAction
  | SetAnswer
  | SubmitAnswers
  | Reset
  | ShowCorrectAnswers;

export const initialState: State = {
  courseSlug: '',
  courseName: '',
  questions: [],
  currentQuestionIndex: 0,
  answers: {},
  submitted: false,
  timeExceed: false,
  isCorrectAnswersShown: false,
};

const questionsReducer: Reducer<State, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'START_EXAM':
      return { ...state, startedAt: action.payload.startedAt };
    case 'SET_CURRENT_QUESTION_INDEX_ACTION':
      return { ...state, currentQuestionIndex: action.payload.index };
    case 'SET_ANSWER': {
      const { question, answer } = action.payload;

      return {
        ...state,
        answers: { ...state.answers, [question]: answer },
      };
    }
    case 'SUBMIT_ANSWERS':
      return {
        ...state,
        submitted: true,
        timeExceed: action.payload.status === 'TIME_EXCEED',
      };
    case 'RESET':
      return { ...initialState, questions: state.questions };
    case 'SHOW_CORRECT_ANSWERS':
      return { ...state, currentQuestionIndex: 0, isCorrectAnswersShown: true };
    default:
      throw new UnreachableCaseError(action);
  }
};

export default questionsReducer;
