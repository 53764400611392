import { courses as types } from '@codesass/types';

import QuestionsProvider from 'modules/courses/providers/QuestionsProvider';

import Questions from '../Questions';

import ExamResult from './ExamResult';
import ViewCorrectAnswers from './ViewCorrectAnswers';

type ExamLessonProps = types.ExamLesson & {
  courseSlug: types.Course['slug'];
};

const ExamLesson = ({ type, courseSlug, slug, questions }: ExamLessonProps) => {
  return (
    <QuestionsProvider questions={questions}>
      {({ submitted, isCorrectAnswersShown }) => {
        switch (true) {
          case isCorrectAnswersShown:
            return <ViewCorrectAnswers></ViewCorrectAnswers>;
          case submitted:
            return <ExamResult></ExamResult>;
          default:
            return (
              <Questions
                courseSlug={courseSlug}
                slug={slug}
                lessonType={type}
              ></Questions>
            );
        }
      }}
    </QuestionsProvider>
  );
};

export default ExamLesson;
