import { useCallback, useContext, useMemo } from 'react';

import { courses as api } from '@codesass/api';
import { courses as types } from '@codesass/types';

import { useDispatch } from 'react-redux';

import * as actions from 'modules/courses/slice';

import QuestionsContext from '../contexts/Questions';

type LessonType = Exclude<types.Lesson['type'], 'video'>;

export type Submit = {
  slug: types.Lesson['slug'];
  lessonType: LessonType;
  status?: 'SUCCESS' | 'TIME_EXCEED';
};

const useQuestions = () => {
  const reduxDispatch = useDispatch();
  const { dispatch, ...state } = useContext(QuestionsContext);
  const {
    courseSlug,
    courseName,
    certificationTitle,
    answers,
    questions,
    currentQuestionIndex,
  } = state;

  const startExam = useCallback(
    (lessonType: LessonType) => {
      const startedAt = new Date();

      dispatch({
        type: 'START_EXAM',
        payload: {
          startedAt,
        },
      });

      if (lessonType === 'certificationExam') {
        api.setCertificationExamStatus({
          courseSlug,
          courseName,
          certificationTitle,
          status: {
            type: 'start',
            startedAt,
          },
        });
      }
    },
    [certificationTitle, courseName, courseSlug, dispatch]
  );

  const changeCurrentQuestionIndex = useCallback(
    (index: number) => {
      dispatch({
        type: 'SET_CURRENT_QUESTION_INDEX_ACTION',
        payload: { index },
      });
    },
    [dispatch]
  );

  const setAnswer = useCallback(
    (question: number, answer: number) => {
      dispatch({ type: 'SET_ANSWER', payload: { question, answer } });
    },
    [dispatch]
  );

  const isAnswerSelected = useCallback(
    (question: number) => {
      return typeof answers?.[question] === 'number';
    },
    [answers]
  );

  const reset = useCallback(() => dispatch({ type: 'RESET' }), [dispatch]);

  const showCorrectAnswers = useCallback(
    () => dispatch({ type: 'SHOW_CORRECT_ANSWERS' }),
    [dispatch]
  );

  const allAnswersSelected = useMemo(() => {
    const answerValues = Object.values(answers);

    if (questions.length !== answerValues.length) return false;
    if (answerValues.some(a => typeof a !== 'number')) return false;

    return true;
  }, [answers, questions.length]);

  const correctAnswersCount = useMemo(() => {
    let count = 0;

    for (const [question, answer] of Object.entries(answers)) {
      if (questions[+question]?.answer === answer) {
        count++;
      }
    }

    return count;
  }, [answers, questions]);

  const correctPercent = useMemo(
    () => ((correctAnswersCount / questions.length) * 100).toFixed(2),
    [correctAnswersCount, questions.length]
  );

  const submit = useCallback(
    ({ slug, lessonType, status = 'SUCCESS' }: Submit) => {
      let viewStatus: types.LessonViewStatus = {
        exam: {
          type: 'Exam' as const,
          status: 'Done' as const,
          score: +correctPercent,
        },
        certificationExam: {
          type: 'CertificationExam' as const,
          status: 'Done' as const,
        },
      }[lessonType];

      api.setLessonViewStatus({
        courseSlug,
        lessonSlug: slug,
        ...viewStatus,
      });
      reduxDispatch(
        actions.setLessonViewStatus({
          slug,
          viewStatus,
        })
      );

      if (lessonType === 'certificationExam') {
        const answersArr = Array.from<number>({
          length: Object.keys(answers).length,
        });

        for (const [k, v] of Object.entries(answers)) {
          answersArr[+k] = v;
        }

        if (!certificationTitle) {
          throw new Error('certificationTitle must be provided');
        }

        api.setCertificationExamStatus({
          courseSlug,
          courseName,
          certificationTitle,
          status: {
            type: status === 'SUCCESS' ? 'done' : 'timeExceed',
            doneAt: new Date(),
            answers: answersArr,
          },
        });
      }

      dispatch({ type: 'SUBMIT_ANSWERS', payload: { status } });
    },
    [
      correctPercent,
      courseSlug,
      reduxDispatch,
      dispatch,
      answers,
      certificationTitle,
      courseName,
    ]
  );

  const value = useMemo(
    () => ({
      ...state,
      startExam,
      currentQuestion: questions[currentQuestionIndex],
      changeCurrentQuestionIndex,
      setAnswer,
      isAnswerSelected,
      allAnswersSelected,
      submit,
      reset,
      showCorrectAnswers,
      correctAnswersCount,
      correctPercent,
    }),
    [
      state,
      startExam,
      questions,
      currentQuestionIndex,
      changeCurrentQuestionIndex,
      setAnswer,
      isAnswerSelected,
      allAnswersSelected,
      submit,
      reset,
      showCorrectAnswers,
      correctAnswersCount,
      correctPercent,
    ]
  );

  return value;
};

export default useQuestions;
