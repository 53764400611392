import {
  Box,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';

import { ChangeEvent, useCallback, useEffect, useRef } from 'react';

import { courses as types } from '@codesass/types';

import useQuestions, { Submit } from 'modules/courses/hooks/useQuestions';

import { MDXRenderer } from 'modules/ui/components/shared';

import Timer from 'modules/ui/components/Timer';

import QuestionSelectorBar from './QuestionSelectorBar';

type QuestionsProps = {
  slug: types.Lesson['slug'];
  lessonType: types.Lesson['type'];
};

const Questions = ({ slug, lessonType }: QuestionsProps) => {
  const {
    currentQuestion,
    currentQuestionIndex,
    setAnswer,
    answers,
    allAnswersSelected,
    startedAt,
    time,
    submit,
  } = useQuestions();
  const intervalRef = useRef<NodeJS.Timer>();

  const changeAnswer = useCallback(
    (question: number) => (_: ChangeEvent<HTMLInputElement>, answer: string) => {
      setAnswer(question, Number(answer) + 1);
    },
    [setAnswer]
  );

  const processSubmission = useCallback(
    (status: Submit['status'] = 'SUCCESS') => {
      if (lessonType === 'video') return;

      submit({
        slug,
        lessonType,
        status,
      });
    },
    [lessonType, slug, submit]
  );

  const handleSubmit = useCallback(
    () => processSubmission(),
    [processSubmission]
  );

  const handleTimeout = useCallback(
    () => processSubmission('TIME_EXCEED'),
    [processSubmission]
  );

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (!startedAt) return;
      if (!time) return;
      if (Date.now() / 1_000 - startedAt.getTime() / 1_000 > time) {
        handleTimeout();
      }
    }, 1_000);

    return () => clearInterval(intervalRef.current as NodeJS.Timer);
  }, [handleSubmit, handleTimeout, processSubmission, startedAt, time]);

  if (!currentQuestion) return null;

  return (
    <>
      <Box fontSize={theme => theme.typography.h6.fontSize}>
        <MDXRenderer>{currentQuestion.question}</MDXRenderer>
      </Box>
      <FormControl sx={{ mb: 8, width: '100%' }}>
        <RadioGroup
          key={currentQuestionIndex}
          name={`question-${currentQuestionIndex + 1}`}
          onChange={changeAnswer(currentQuestionIndex)}
        >
          {currentQuestion.choices.map((choice, index) => {
            return (
              <FormControlLabel
                key={`${currentQuestionIndex}-${index}`}
                value={index}
                control={<Radio />}
                label={<MDXRenderer>{choice}</MDXRenderer>}
                checked={answers[currentQuestionIndex] === index + 1}
                sx={{
                  alignItems: 'start',
                  width: '100%',
                  '& p,pre': { mt: '9px', overflow: 'auto' },
                  '& .MuiTypography-root': { flex: 1, overflow: 'auto' },
                }}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      {time && (
        <Box
          sx={{
            position: 'fixed',
            bottom: theme => theme.spacing(1),
          }}
        >
          <Timer time={time}></Timer>
        </Box>
      )}
      <Box
        sx={{
          position: 'fixed',
          bottom: theme => theme.spacing(1),
          right: theme => theme.spacing(1),
        }}
      >
        <QuestionSelectorBar></QuestionSelectorBar>
        {allAnswersSelected && (
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              px: 2,
              mx: 1,
              fontSize: theme => theme.typography.h6.fontSize,
            }}
          >
            ส่งคำตอบ
          </Button>
        )}
      </Box>
    </>
  );
};

export default Questions;
