import { Typography, Button, Box } from '@mui/material';

import { courses as types } from '@codesass/types';
import { useCallback } from 'react';

import useQuestions from 'modules/courses/hooks/useQuestions';

type TermsAndConditionsProps = {
  time: types.CertificationExamLesson['time'];
  questionsCount: number;
};

const TermsAndConditions = ({
  time,
  questionsCount,
}: TermsAndConditionsProps) => {
  const { startExam } = useQuestions();

  const accept = useCallback(() => {
    startExam('certificationExam');
  }, [startExam]);

  return (
    <>
      <Typography variant="h5" component="h1" align="center" my={2}>
        เงื่อนไขการสอบประเมินผลการเรียน
      </Typography>
      <Box bgcolor="secondary.main" p={2} borderRadius="5px">
        <Typography fontWeight="bold" component="span">
          สำคัญมาก:{' '}
        </Typography>
        <Typography component="span">
          ระหว่างการทดสอบห้ามปิดเว็บเบราว์เซอร์หรือออกจากหน้าการทดสอบเป็นอันขาด
          มิเช่นนั้นคุณจะไม่สามารถกลับมาทำแบบทดสอบได้อีกครั้ง
        </Typography>
      </Box>
      <Box
        component="ul"
        sx={{
          listStyleType: 'decimal',
          fontSize: '1.25rem',
          color: theme => theme.palette.grey[800],
        }}
      >
        <li>
          แบบทดสอบชุดนี้สามารถเข้าทำได้ตราบใดที่อายุของคอร์สยังไม่สิ้นสุด
          เราแนะนำให้คุณเริ่มทำแบบทดสอบชุดนี้เมื่อคุณมีความพร้อมแล้วเท่านั้น
          หากคุณได้ดำเนินการทำแบบทดสอบไปแล้วจะไม่สามารถทำซ้ำเป็นครั้งที่ 2 ได้
        </li>
        <li>
          แบบทดสอบมีทั้งหมด {questionsCount} ข้อ
          เป็นแบบเลือกตอบที่มีข้อถูกเพียงข้อเดียว
        </li>
        <li>
          เวลาที่ใช้ในการทำแบบทดสอบคือ {(time / 60).toFixed(2)} นาที
          เมื่อครบกำหนดเวลาการทดสอบจะยุติลงแม้ผู้ทำแบบทดสอบจะยังทำข้อสอบไม่ครบทุกข้อก็ตาม
        </li>
        <li>
          การจับเวลาทดสอบจะเริ่มต้นเมื่อผู้ทดสอบกดปุ่มยอมรับเงื่อนไขล่างข้อตกลงนี้
        </li>
        <li>
          ชุดทดสอบนี้ไม่อนุญาตให้ผู้ทำการทดสอบปิดหน้าต่างการทดสอบหรือเปลี่ยนหน้าเพจ
          มิเช่นนั้นคุณจะไม่สามารถกลับมาทำแบบทดสอบได้อีกครั้ง
        </li>
        <li>
          ชุดทดสอบนี้จะไม่สามารถกดส่งคำตอบได้เว้นแต่ทุกข้อคำถามจะได้รับการเลือกคำตอบแล้ว
        </li>
        <li>
          ผู้ทำการทดสอบจะได้รับประกาศนียบัตรรับรองผลการสอบเมื่อคะแนนจากการทดสอบมากกว่าหรือเท่ากับ
          70% ของคะแนนเต็ม
        </li>
      </Box>
      <Button
        variant="contained"
        onClick={accept}
        sx={{ display: 'block', mx: 'auto' }}
      >
        ยอมรับเงื่อนไข
      </Button>
    </>
  );
};

export default TermsAndConditions;
