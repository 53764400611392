import { ReactNode } from 'react';
import { PageProps } from 'gatsby';

import { courses as types } from '@codesass/types';

import LessonDetails from 'modules/courses/components/lessons/Lesson';

type LessonTemplatePageContext = Pick<
  types.Lesson,
  'type' | 'title' | 'slug'
> & {
  courseSlug: types.CourseDetails['slug'];
  courseName: types.CourseDetails['title'];
  unitSlug: types.Unit['slug'];
};

const LessonTemplate = ({
  pageContext: ctx,
}: PageProps<Record<string, never>, LessonTemplatePageContext>): ReactNode => {
  return (
    <LessonDetails
      title={ctx.title}
      courseSlug={ctx.courseSlug}
      courseName={ctx.courseName}
      unitSlug={ctx.unitSlug}
      lessonSlug={ctx.slug}
    ></LessonDetails>
  );
};

export default LessonTemplate;
