import { courses as types } from '@codesass/types';

import CertificationExamLesson from './certification/CertificationExamLesson';
import ExamLesson from './exam/ExamLesson';
import VideoLesson from './VideoLesson';
import LessonAside from './LessonAside';

type LessonContentProps = types.Lesson & {
  courseSlug: types.Course['slug'];
  courseName: types.Course['title'];
};

const LessonDetails = (lesson: LessonContentProps) => {
  switch (lesson.type) {
    case 'video':
      return <VideoLesson {...lesson}></VideoLesson>;
    case 'exam':
      return <ExamLesson {...lesson}></ExamLesson>;
    case 'certificationExam':
      return <CertificationExamLesson {...lesson}></CertificationExamLesson>;
  }
};

const LessonContent = (lesson: LessonContentProps) => {
  return (
    <>
      <LessonDetails {...lesson}></LessonDetails>
      {lesson.type === 'video' && (
        <LessonAside
          slug={lesson.slug}
          courseSlug={lesson.courseSlug}
        ></LessonAside>
      )}
    </>
  );
};

export default LessonContent;
