import ReactPlayer from 'react-player';
import { courses as types } from '@codesass/types';

import { useCallback, useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { BaseReactPlayerProps } from 'react-player/base';

import { courses as api } from '@codesass/api';

import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'modules/courses/slice';
import { isAppleBrowser } from 'modules/shared/browser';
import { getLessonPath } from 'modules/courses/helpers/paths';
import { VIDEO_SAVE_POINT_IN_SECONDS } from 'modules/courses/constants';
import { selectViewStatuses } from 'modules/courses/selectors';

type VideoLessonProps = types.VideoLesson & {
  courseSlug: types.Course['slug'];
};

const VideoPlayer = styled(ReactPlayer)({
  position: 'absolute',
  top: 0,
  left: 0,
});

const VideoLesson = ({ courseSlug, slug, video, meta }: VideoLessonProps) => {
  const dispatch = useDispatch();
  const viewStatuses = useSelector(selectViewStatuses);
  const statuses = viewStatuses?.[slug];
  const [ready, setReady] = useState(false);
  const ref = useRef<ReactPlayer | null>(null);
  const [playing, setPlaying] = useState(false);
  const url = isAppleBrowser() ? `${video}.m3u8` : `${video}.mpd`;

  const setViewStatus = useCallback(
    (seconds: number, status: 'Viewing' | 'Done') => {
      const viewStatus = { type: 'Video' as const, seconds, status };

      api.setLessonViewStatus({
        courseSlug,
        lessonSlug: slug,
        ...viewStatus,
      });
      dispatch(
        actions.setLessonViewStatus({
          slug,
          viewStatus,
        })
      );
    },
    [courseSlug, dispatch, slug]
  );

  const handleReady = useCallback(() => {
    setPlaying(true);
    setReady(true);
  }, []);

  const handleEnded = useCallback(() => {
    if (meta.nextLesson && statuses) {
      setViewStatus(-1, 'Done');
      navigate(getLessonPath(courseSlug, meta.nextLesson));
    }
  }, [courseSlug, meta.nextLesson, setViewStatus, statuses]);

  const handleProgress = useCallback(
    (
      ...[{ playedSeconds }]: Parameters<
        Exclude<BaseReactPlayerProps['onProgress'], undefined>
      >
    ) => {
      const seconds = Math.floor(playedSeconds);
      if (
        seconds % VIDEO_SAVE_POINT_IN_SECONDS === 0 &&
        (statuses || (!statuses && seconds !== 0))
      ) {
        setViewStatus(seconds, 'Viewing');
      }
    },
    [setViewStatus, statuses]
  );

  useEffect(() => {
    let seconds = 0;

    if (statuses?.type === 'Video') {
      seconds = statuses.seconds ?? 0;
    }

    if (!ready) {
      ref.current?.seekTo(seconds, 'seconds');
    }
  }, [slug, statuses, ready]);

  return (
    <Box position="relative" pt="56.25%">
      <VideoPlayer
        ref={ref}
        url={url}
        controls={true}
        playing={playing}
        width="100%"
        height="100%"
        onReady={handleReady}
        onProgress={handleProgress}
        onEnded={handleEnded}
      ></VideoPlayer>
    </Box>
  );
};

export default VideoLesson;
