/* eslint-disable unicorn/no-useless-undefined */
import { useEffect, useState } from 'react';

type UseApiReturnType<Data> = {
  data?: Awaited<Data> | undefined;
  error?: string | undefined;
  isLoading: boolean;
};

function useApi<Data>(fn: () => Data): UseApiReturnType<Data> {
  const [data, setData] = useState<Awaited<Data> | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const data = await fn();
        setData(data);
        setError(undefined);
      } catch (error) {
        setData(undefined);
        setError((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [fn]);

  return { data, error, isLoading };
}

export default useApi;
