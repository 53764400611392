import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

type TimerProps = {
  time: number;
  onTimeout?: () => void;
};

const Timer = ({ time, onTimeout }: TimerProps) => {
  const [remainingTime, setRemainingTime] = useState(time);
  const [minutes, setMinutes] = useState('0');
  const [seconds, setSeconds] = useState('0');

  const setTime = useCallback(() => {
    if (remainingTime <= 0) return onTimeout?.();

    setMinutes(
      Math.floor(remainingTime / 60)
        .toString()
        .padStart(2, '0')
    );
    setSeconds((remainingTime % 60).toString().padStart(2, '0'));
  }, [onTimeout, remainingTime]);

  useEffect(() => {
    setTime();
    const timer = setInterval(() => {
      setRemainingTime(t => t - 1);
    }, 1_000);

    return () => clearInterval(timer);
  }, [setTime]);

  if (!time) return null;
  return (
    <Box
      sx={{
        bgcolor: theme => theme.palette.primary.main,
        borderRadius: theme => theme.shape.borderRadius,
        py: 1,
        px: 2,
      }}
    >
      <Typography variant="h6">
        เหลือเวลา {minutes}:{seconds} นาที
      </Typography>
    </Box>
  );
};

export default Timer;
