import { Box, Typography, ButtonGroup, Button } from '@mui/material';

import Circle from 'react-circle';

import useQuestions from 'modules/courses/hooks/useQuestions';

const ExamResult = () => {
  const {
    correctAnswersCount,
    questions,
    reset,
    showCorrectAnswers,
    correctPercent,
  } = useQuestions();
  const questionsLength = questions.length;

  return (
    <Box textAlign="center">
      <Box my={2}>
        <Circle
          progress={+correctPercent}
          animate={true}
          animationDuration="1s"
          size="200"
        />
      </Box>
      <Typography variant="h6" component="p">
        คุณตอบคำถามถูกเป็นจำนวน{' '}
        <Box color="green" fontWeight="bold" component="span">
          {correctAnswersCount}
        </Box>{' '}
        ข้อจากแบบทดสอบทั้งหมด {questionsLength} ข้อ คิดเป็นสัดส่วน{' '}
        <Box color="green" fontWeight="bold" component="span">
          {correctPercent}%
        </Box>
      </Typography>
      <ButtonGroup
        size="large"
        aria-label="large button group"
        color="inherit"
        sx={{ my: 2 }}
      >
        <Button onClick={reset}>ทำแบบทดสอบอีกครั้ง</Button>
        <Button onClick={showCorrectAnswers}>ดูเฉลย</Button>
      </ButtonGroup>
    </Box>
  );
};

export default ExamResult;
