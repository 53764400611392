import {
  Typography,
  CircularProgress,
  Container,
  IconButton,
  Box,
  Stack,
  Button,
} from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { courses as types } from '@codesass/types';
import { courses as api } from '@codesass/api';
import { Link } from 'gatsby';
import { useCallback } from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { useSelector } from 'react-redux';

import useApi from 'modules/shared/hooks/useApi';

import {
  getCourseRegisterPath,
  getLessonPath,
} from 'modules/courses/helpers/paths';

import { selectProfile } from 'modules/auth/selectors';

import LessonContent from './LessonContent';
import LessonError from './LessonError';

type LessonProps = {
  title: types.Lesson['title'];
  courseSlug: types.CourseDetails['slug'];
  courseName: types.CourseDetails['title'];
  unitSlug: types.Unit['slug'];
  lessonSlug: types.Lesson['slug'];
};

const Lesson = ({
  title,
  courseSlug,
  courseName,
  unitSlug,
  lessonSlug,
}: LessonProps) => {
  const fn = useCallback(() => {
    return api.getLesson(courseSlug, unitSlug, lessonSlug);
  }, [courseSlug, lessonSlug, unitSlug]);
  const profile = useSelector(selectProfile);
  const { data: lesson, isLoading, error } = useApi(fn);
  const { prevLesson, nextLesson } = lesson?.meta ?? {};

  return (
    <Container maxWidth="lg">
      <Box display="flex" alignItems="center">
        <Box>
          <IconButton
            aria-label="go-to-previous-lesson"
            {...(prevLesson
              ? { component: Link, to: getLessonPath(courseSlug, prevLesson) }
              : { disabled: true })}
          >
            <KeyboardArrowLeft></KeyboardArrowLeft>
          </IconButton>
        </Box>
        <Typography
          component="h1"
          variant="h5"
          align="center"
          sx={{ py: 2, flex: 1, mx: 2 }}
        >
          {title}
        </Typography>
        <Box>
          <IconButton
            aria-label="go-to-next-lesson"
            {...(nextLesson
              ? { component: Link, to: getLessonPath(courseSlug, nextLesson) }
              : { disabled: true })}
          >
            <KeyboardArrowRight></KeyboardArrowRight>
          </IconButton>
        </Box>
      </Box>
      {isLoading ? (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        ></CircularProgress>
      ) : (
        <>
          {error && <LessonError error={error} slug={courseSlug}></LessonError>}
          {!profile && (
            <LessonError
              error="โปรดลงทะเบียนเพื่อเข้าถึงเนื้อหาของบทเรียนนี้"
              slug={courseSlug}
            ></LessonError>
          )}
          {!error && profile && lesson && (
            <LessonContent
              {...lesson}
              courseSlug={courseSlug}
              courseName={courseName}
            ></LessonContent>
          )}
        </>
      )}
    </Container>
  );
};

export default Lesson;
