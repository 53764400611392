/* eslint-disable unicorn/consistent-destructuring */
import { courses as api } from '@codesass/api';
import { courses as types } from '@codesass/types';

import { useCallback, useEffect, useState } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import { StaticImage } from 'gatsby-plugin-image';

import { useSelector } from 'react-redux';

import QuestionsProvider from 'modules/courses/providers/QuestionsProvider';
import Certification from 'modules/ui/components/Certification';

import { selectProfile } from 'modules/auth/selectors';

import Link from 'modules/ui/components/Link';

import Questions from '../Questions';

import TermsAndConditions from './TermsAndConditions';
import TimeExceed from './TimeExceed';
import ViewResult from './ViewResult';

type CertificationExamProps = (
  | types.CertificationExamLesson
  | types.ExamLesson
) & {
  courseSlug: types.Course['slug'];
  courseName: types.Course['title'];
  certificationTitle: types.CertificationExamLesson['certificationTitle'];
};

const CertificationExam = (props: CertificationExamProps) => {
  const { courseSlug, courseName, certificationTitle, type, slug, questions } =
    props;
  const profile = useSelector(selectProfile);
  const [status, setStatus] = useState<types.CertificationExamStatus>();
  const loadStatus = useCallback(async () => {
    const examStatus = await api.getCertificationExamStatus(courseSlug);

    setStatus(examStatus);
  }, [courseSlug]);

  useEffect(() => {
    loadStatus();
  }, [loadStatus]);

  if (type === 'certificationExam') {
    if (status?.verified) {
      return (
        <Box>
          <Certification id={status.id} downloadable={true}></Certification>
        </Box>
      );
    }

    if (status?.type && ['start', 'done', 'timeExceed'].includes(status.type)) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box maxWidth={350} my={2}>
            <StaticImage
              src="../../../../../assets/images/lesson-permission-denied.png"
              alt="Creator"
            />
          </Box>
          <Typography textAlign="center" m={2} variant="h5">
            เรากำลังดำเนินการตรวจสอบผลการทดสอบของท่าน
          </Typography>
        </Box>
      );
    }
    if (!profile?.name) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box maxWidth={350} my={2}>
            <StaticImage
              src="../../../../../assets/images/lesson-permission-denied.png"
              alt="Creator"
            />
          </Box>
          <Typography textAlign="center" m={2} variant="h5">
            การทดสอบเพื่อขอใบรับรองหลักสูตร (Certification)
            จำเป็นต้องเพิ่มชื่อ-นามสกุลของคุณในระบบ
            ข้อมูลส่วนนี้จะใช้ประกอบการออกใบรับรองด้วยชื่อของคุณ{' '}
            <Link to="/dashboard/profile">
              ไปหน้าจัดการบัญชีเพื่อเพิ่มชื่อ-นามสกุลของคุณ
            </Link>
          </Typography>
        </Box>
      );
    }
  }

  return (
    <QuestionsProvider
      courseSlug={courseSlug}
      courseName={courseName}
      certificationTitle={certificationTitle}
      questions={questions}
      time={props.type === 'certificationExam' ? props.time : undefined}
    >
      {({ submitted, startedAt, timeExceed }) => {
        if (submitted) return <ViewResult></ViewResult>;
        if (props.type !== 'certificationExam') return null;

        switch (true) {
          case timeExceed:
            return <TimeExceed></TimeExceed>;
          case startedAt === undefined:
            return (
              <TermsAndConditions
                time={props.time}
                questionsCount={questions.length}
              ></TermsAndConditions>
            );
          case startedAt !== undefined:
            return <Questions slug={slug} lessonType={type}></Questions>;
        }
      }}
    </QuestionsProvider>
  );
};

export default CertificationExam;
